import React from 'react';
import Cost from './Cost';
import { amazon, food } from '../lib/groups';

export default class Other extends React.Component {
  state = {
    view: ''
  }

  showCosts = type => {
    this.setState({ view: this.state.view === type ? '' : type });
  }

  render() {
    return <div>
      <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <td>Group</td>
            <td>Total</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><a onClick={() => this.showCosts('amazon')}>Amazon</a></td>
            <td>{amazon(this.props.entities.get('cost'))}</td>
          </tr>
          {this.state.view === 'amazon' && 
            amazon(this.props.entities.get('cost'), true).map((cc, j) => <tr key={j} style={{ backgroundColor: '#EFEFEF' }}>
              <td colSpan="2"><Cost {...this.props} cost={cc} /></td>
            </tr>)}
          <tr>
            <td><a onClick={() => this.showCosts('food')}>Food</a></td>
            <td>{food(this.props.entities.get('cost'))}</td>
          </tr>
          {this.state.view === 'food' && 
            food(this.props.entities.get('cost'), true).map((cc, j) => <tr key={j} style={{ backgroundColor: '#EFEFEF' }}>
              <td colSpan="2"><Cost {...this.props} cost={cc} /></td>
            </tr>)}
        </tbody>
      </table>
    </div>;
  }
}
