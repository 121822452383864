import React from 'react';
import { state } from '../lib/state';

export default class UploadCosts extends React.Component {
  state = {
    file: null,
    reset: 'append'
  }

  onChange = e => {
    this.setState({ file: e.target.files[0] });
  }

  onRadioChange = e => {
    this.setState({ reset: e.target.value });
  }

  save = () => {
    const data = new FormData();
    data.append('reset', this.state.reset);
    data.append('file', this.state.file);

    this.props.uploadCosts(data)
      .then(res => this.props.dismiss());
  }

  render() {
    return <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Upload  Costs</p>
          <button className="delete" aria-label="close" onClick={this.props.dismiss}></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Item</label>
            <div className="control">
              <input className="input" name="file" type="file" onChange={this.onChange} />
            </div>
          </div>

          <div className="control">
            <label className="radio">
              <input type="radio" name="reset" value="reset" onChange={this.onRadioChange} checked={this.state.reset === 'reset'} /> Reset
            </label>
            <label className="radio">
              <input type="radio" name="reset" value="append" onChange={this.onRadioChange} checked={this.state.reset === 'append'} /> Append
            </label>
          </div>

        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={this.save}>Upload</button>
          <button className="button" onClick={this.props.dismiss}>Cancel</button>
        </footer>
      </div>
    </div>
  }
}
