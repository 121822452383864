import { fromJS } from 'immutable';

export const state = fromJS({
  categories: [
    '',
    'amazon',
    'bills',
    'food',
    'gas',
    'recreation',
    'retail',
    'unplanned'
  ]
});
