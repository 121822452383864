import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import AddCost from './AddCost';
import UploadCosts from './UploadCosts';
import { addCost, uploadCosts, redirect } from '../actions/costs';

class Nav extends React.Component {
  state = {
    nav: false,
    adding: false,
    uploading: false
  }

  add = () => {
    this.setState({ adding: true, uploading: false });
  }

  upload = () => {
    this.setState({ adding: false, uploading: true });
  }

  dismiss = () => {
    this.setState({ adding: false, uploading: false });
  }

  toggleNav = () => {
    this.setState({ nav: !this.state.nav });
  }

  render() {
    return <nav className="navbar">
      <div className={classNames('navbar-brand', {
        'is-hidden': !this.props.users.get('user').is_logged_in
      })}>
        <Link to="/" className="navbar-item title">
          Costs
        </Link>
        <span className={classNames('navbar-burger burger', {
          'is-active': this.state.nav
        })} onClick={this.toggleNav} data-target="navbarMenuHeroA">
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div className={classNames('navbar-menu', {
        'is-active': this.state.nav
      })}>
      <div className={classNames('navbar-end', {
        'is-hidden': !this.props.users.get('user').is_logged_in
      })}>
          {this.props.costs.get('modified') !== '' && <div className="navbar-item">
            last updated: {this.props.costs.get('modified')}
          </div>}
          <Link to="/costs" onClick={this.toggleNav} className="navbar-item">
            View Costs
          </Link>
          <a className="navbar-item" onClick={this.add}>
            Add Cost
          </a>
          <a className="navbar-item" onClick={this.upload}>
            Upload Costs
          </a>
        </div>
      </div>

      {this.state.adding && <AddCost 
        {...this.props} 
        dismiss={this.dismiss} />}

      {this.state.uploading && <UploadCosts 
        {...this.props} 
        dismiss={this.dismiss} />}
    </nav>
  }
}

function mapStateToProps(state) {
  return {
    costs: state.costs,
    entities: state.entities,
    users: state.users
  };
}

export const NavContainer = connect(
  mapStateToProps,
  { addCost, uploadCosts, redirect }
)(Nav);
