import request from './request';
import { server } from '../constants/SiteVariables';
import { assign } from 'lodash';
import Cookies from 'universal-cookie';

const cookie = new Cookies();

function addAuthorization(options) {
  const user = cookie.get('user');

  if (user && user.token) {
    assign(options.headers, { "X-Access-Token": user.token });
  }

  return options;
}

/**
 * @function Network
 * @description Factory function to create a object that can send
 * requests to a specific resource on the server.
 * @param {string} resource The resource used for config
 */
const Network = resource => {
  let buildURL = ({ params, id, resource } = {}) => {
    let parameters = [
      server()
    ];

    if (resource) parameters = parameters.concat([resource]);
    if (id) parameters = parameters.concat([id]);

    if (params) {
      return parameters.join('/') + params;
    }
    return parameters.join('/');
  }


  // Default options used for every request
  let defaultOptions = {
    mode: 'cors',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  };

  return {

    /**
     * @function post
     * @description Make a POST request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    post: (path, body, options = {}) => {
      return request(buildURL(path), assign(
        options,
        addAuthorization(defaultOptions),
        {
          method: 'POST',
          body: JSON.stringify(body)
        }
      ));
    },

    /**
     * @function upload
     * @description Make a POST request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    upload: (path, body, options = {}) => {
      return request(buildURL(path), assign(
        options, 
        addAuthorization({ headers: {} }),
        {
          method: 'POST',
          body: body
        }
      ));
    },

    /**
     * @function post
     * @description Make a GET request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    get: (path, options = {}) => {
      return request(buildURL(path), assign(
        options,
        addAuthorization(defaultOptions),
        { method: 'GET' }
      ));
    },

    /**
     * @function edit
     * @description Make a PUT request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    edit: (path, body, options = {}) => {
      return request(buildURL(path), assign(
        options,
        addAuthorization(defaultOptions),
        { 
          method: 'PUT',
          body: JSON.stringify(body)
        }
      ));
    },

    /**
     * @function patch
     * @description Make a PATCH request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    patch: (path, body, options = {}) => {
      return request(buildURL(path), assign(
        options,
        addAuthorization(defaultOptions),
        { 
          method: 'PATCH',
          body: JSON.stringify(body)
        }
      ));
    },

    /**
     * @function delete
     * @description Make a DELETE request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    delete: (path, body, options = {}) => {
      return request(buildURL(path), assign(
        options,
        addAuthorization(defaultOptions),
        { 
          method: 'DELETE',
          body: JSON.stringify(body)
        }
      ));
    },

    ping: () => request(buildURL(), { method: 'GET' })
  };
};

export default Network;
