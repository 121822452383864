export const amazon = (costs, items) => {
  const amz = new RegExp('amz', 'i');
  const ama = new RegExp('ama', 'i');

  if (!items) {
    return costs
      .filter(c => c.item.match(amz) || c.item.match(ama))
      .map(c => parseFloat(c.cost))
      .valueSeq()
      .toArray()
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  } else {
    return costs
      .filter(c => c.item.match(amz) || c.item.match(ama))
      .valueSeq();
  }
}

export const food = (costs, items) => {
  const food = new RegExp('food', 'i');
  const groceries = new RegExp('grocer', 'i');

  if (!items) {
      return costs
      .filter(c => c.category.match(food) || c.category.match(groceries))
      .map(c => parseFloat(c.cost))
      .valueSeq()
      .toArray()
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  } else {
    return costs
      .filter(c => c.category.match(food) || c.category.match(groceries))
      .valueSeq();
  }
}
