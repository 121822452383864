import { fromJS } from 'immutable';
import * as ActionTypes from '../constants/ActionTypes';

const costsState = fromJS({
  modified: '',
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function users(state = costsState, action) {
  switch(action.type) {
    case ActionTypes.MODIFIED:
      return setState(state, state.set('modified', action.payload));

    default:
      return state;
  }
}
