import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import users from './users';
import costs from './costs';
import entities from './entities'

const createRootReducer = (history) => combineReducers({ 
  router: connectRouter(history),
  costs,
  users,
  entities
});

export default createRootReducer;
