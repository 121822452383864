import React from 'react';
import { connect } from 'react-redux';
import { deleteCost, updateCost } from '../actions/costs';
import Cost from './Cost';
import EditCost from './EditCost';

function sorted(a, b, column) {
  if (column === 'cost') {
    if (parseFloat(a[column]) < parseFloat(b[column])) { return -1; }
    if (parseFloat(a[column]) > parseFloat(b[column])) { return 1; }
    if (parseFloat(a[column]) === parseFloat(b[column])) { return 0; }
  }

  if (a[column] < b[column]) { return -1; }
  if (a[column] > b[column]) { return 1; }
  if (a[column] === b[column]) { return 0; }
}

class Costs extends React.Component {
  state = {
    sort: 'item',
    desc: true,
    filter: ''
  }

  costs = filter => {
    let match;
    if (filter !== '') {
      match = new RegExp(filter, 'i');
    }

    let data = this.props.entities.get('cost')
      .filter(c => filter !== '' ? c.item.match(match) : c)
      .sort((a, b) => sorted(a, b, this.state.sort))
      .valueSeq();

    return this.state.desc ? data : data.reverse();
  }

  sort = column => {
    this.setState({ sort: column, desc: !this.state.desc });
  }

  filter = e => {
    this.setState({ filter: e.target.value });
  }

  separator = () => {
    return <span className="has-text-grey" style={{ padding: '0 5px 0 5px' }}>|</span>;
  }

  render() {
    return <div>
      <div className="box">
        <div className="columns">
          <div className="column is-two-thirds">
            <div className="field">
              <p className="control">
                <input className="input" type="email" placeholder="Filter" onChange={this.filter} />
              </p>
            </div>
          </div>

          <div className="column has-text-right">
            <div className="is-size-4">
              <strong>Total:</strong> {this.costs(this.state.filter).reduce((total, c) => total + parseFloat(c.cost), 0).toFixed(2)}
            </div>

            <a onClick={() => this.sort('date')}>
              <span>Date</span>
            </a>
            {this.separator()}
            <a onClick={() => this.sort('item')}>
              <span>Item</span>
            </a>
            {this.separator()}
            <a onClick={() => this.sort('cost')}>
              <span>Cost</span>
            </a>
            {this.separator()}
            <a onClick={() => this.sort('category')}>
              <span>Category</span>
            </a>
          </div>
        </div>
      </div>

      {this.costs(this.state.filter)
        .map((c, i) => <Cost 
          {...this.props} 
          key={i} 
          cost={c} />)}

      {this.state.editing && <EditCost 
        {...this.props} 
        cost={this.state.editing}
        dismiss={this.dismiss} />}
      </div>
  }
}

function mapStateToProps(state) {
  return {
    entities: state.entities
  };
}

export const CostsContainer = connect(
  mapStateToProps,
  { deleteCost, updateCost }
)(Costs);
