import React from 'react';
import { connect } from 'react-redux';
import { login } from '../actions/users';

class Login extends React.Component {
  state = {
    passphrase: ''
  }

  onChange = e => {
    this.setState({ passphrase: e.target.value });
  }

  login = () => {
    this.props.login(this.state.passphrase);
  }

  render() {
    return <section className="hero is-large">
      <div className="hero-body">
        <div className="container">
          <h1 className="title">
            Costs Aggregator
          </h1>
          <div className="field">
            <div className="control">
              <input className="input" type="text" onChange={this.onChange} placeholder="Passphrase" />
            </div>
          </div>
          <div className="field">
            <div className="control">
              <button className="button is-link" onClick={this.login}>Login</button>
            </div>
          </div>
        </div>
      </div>
    </section> 
  }
}

function mapStateToProps(state) {
  return {
    entities: state.entities
  };
}

export const LoginContainer = connect(
  mapStateToProps,
  { login: login }
)(Login);
