import React from 'react';
import { food } from '../lib/groups';

const types = [{
  label: 'Free Spend',
  amount: 1000,
  method: () => false
}, {
  label: 'Food Budget',
  amount: 1500,
  method: food
}];

export default class Free extends React.Component {
  types = () => {
    let rows = []

    types.forEach((t, i) => {
      rows.push(<tr key={i}>
        <td>{t.label}</td>
        <td>{t.amount}</td>
        <td>{t.amount - t.method(this.props.entities.get('cost'))}</td>
      </tr>);
    });

    return rows;
  }

  render() {
    return <div>
      <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <td>type</td>
            <td>Total Budget</td>
            <td>Amount Remaining</td>
          </tr>
        </thead>
        <tbody>
          {this.types()}
        </tbody>
      </table>
    </div>;
  }
}
