import { fromJS } from 'immutable';
import * as ActionTypes from '../constants/ActionTypes';
import UserRecord from '../records/user';

const usersState = fromJS({
  loading: true,
  reset: false,
  user: new UserRecord(),
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function users(state = usersState, action) {
  switch(action.type) {
    case ActionTypes.LOADING:
      return setState(state, state.set('loading', action.payload));

    case ActionTypes.LOGIN:
      return setState(state, state.set('user', new UserRecord({ is_logged_in: true })));

    default:
      return state;
  }
}
