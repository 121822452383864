import React from 'react';
import { connect } from 'react-redux'; 

import {
  Link,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

import { store } from './store';

import { LoginContainer } from './components/Login';
import { MainContainer } from './components/Main';
import { CostsContainer } from './components/Costs';

import { NavContainer } from './components/Nav';

import './styles/App.scss';

class PrivateRouteContainer extends React.Component {
  render() {
    const {
      users,
      component: Component,
      ...props
    } = this.props

    return (
      <Route
        {...props}
        render={props =>
          users.get('user').is_logged_in ? <Component {...props} /> : (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location }
            }} />
      )} />
    )
  }
}

const PrivateRoute = connect(state => ({
  users: state.users
}))(PrivateRouteContainer);

const ConnectedSwitch = connect(state => ({
  location: state.location
}))(Switch)

function App() {
  return (
    <>
      <NavContainer />

      <div className="main">
        <ConnectedSwitch>
          <Route path="/login" component={LoginContainer} />
          <PrivateRoute exact path="/" component={MainContainer} />
          <PrivateRoute path="/costs" component={CostsContainer} />
        </ConnectedSwitch>
      </div>
    </>
  );
}

export default App;
