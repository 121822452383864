import * as ActionTypes from '../constants/ActionTypes';
import { push } from 'connected-react-router';
import Network from '../lib/network';
import Cookies from 'universal-cookie';
import { 
  clearEntity,
  receiveEntity,
  receiveEntities 
} from './entities';
import { getCosts } from './costs';

const api = Network();
const cookie = new Cookies();

const path = resource => {
  if (!resource) resource = '';
  return { resource: 'users/' + resource };
}

export const loading = bool => ({
  type: ActionTypes.LOADING,
  payload: bool
});

export const login = passphrase => {
  return (dispatch, getState) => {
    api.post({
      resource: 'login'
    }, {
      passphrase: passphrase
    })
    .then(res => {
      cookie.set('user', { 
        token: res.token 
      }, { path: '/' });

      return dispatch({
        type: ActionTypes.LOGIN
      });
    })
    .then(res => dispatch(getCosts()))
    .then(res => dispatch(push('/')));
  }
}

export const isUserLoggedIn = () => {
  return (dispatch, getState) => {
    let savedUser = cookie.get('user');

    if (savedUser && savedUser.token) {
      dispatch({
        type: ActionTypes.LOGIN
      });

      return new Promise((resolve, reject) => resolve(true));
    }

    return new Promise((resolve, reject) => resolve(false));
  }
}
