import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { history, store } from './store';
import { getCosts } from './actions/costs';
import { isUserLoggedIn } from './actions/users';
import './styles/index.scss';
import App from './App';

store.dispatch(isUserLoggedIn())
  .then(res => res && store.dispatch(getCosts()))
  .then(res => ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>, 
      document.getElementById('root')
    ));
