import { fromJS, OrderedMap } from 'immutable';
import * as ActionTypes from '../constants/ActionTypes';
import User from '../records/user';
import Cost from '../records/cost';

const models = {
  user: User,
  cost: Cost
}

const entitiesState = fromJS({
  cost: new OrderedMap(),
  user: new OrderedMap()
});

function setState(state, newState) {
  return state.merge(newState);
}

export default function entities(state = entitiesState, action) {
  switch(action.type) {
    case ActionTypes.CLEAR_ENTITIES:
      if (action.payload) {
        return setState(state, state.set(action.payload, entitiesState.get(action.payload)));
      }
      return entitiesState;

    case ActionTypes.CLEAR_ENTITY:
      if (!action.payload) return state;
      return setState(state, state.deleteIn([action.entity ? action.entity : action.payload.type, action.payload.id]));

    case ActionTypes.RECEIVE_ENTITIES:
      if (action.payload.data && action.payload.data.length < 1) return state;

      action.payload.data.map(record => state = setState(state, state.setIn([action.payload.type, record.id], new models[action.payload.type](fromJS(record)))));
      return state;

    case ActionTypes.RECEIVE_ENTITY:
      return setState(state, state.setIn([action.payload.type, action.payload.data.id], new models[action.payload.type](fromJS(action.payload.data))));

    default:
      return state;
  }
}
