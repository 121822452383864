import React from 'react';
import EditCost from './EditCost';

export default class Cost extends React.Component {
  state = {
    editing: false
  }

  delete = cost => {
    if (!cost) return false;

    this.props.deleteCost(cost);
  }

  toggleEdit = () => {
    this.setState({ editing: !this.state.editing });
  }

  render() {
    return <div className="box">
    <div className="columns is-mobile">
      <div className="column is-half">
        <p className="subtitle is-6">
          <strong>{this.props.cost.date}</strong><br/>
          {this.props.cost.item}
        </p>
      </div>

      <div className="column is-half has-text-right">
        <p className="subtitle is-3 has-text-info">
          {this.props.cost.cost} 
        </p>
        {this.props.cost.category}
      </div>
    </div>

    <div className="has-text-right">
      <button className="button is-small is-info" onClick={this.toggleEdit}>Edit</button> <button className="button is-small is-danger" onClick={() => this.delete(this.props.cost)}>Delete</button>
    </div>

    {this.state.editing && <EditCost 
      {...this.props} 
      active={this.state.editing}
      cost={this.props.cost}
      dismiss={this.toggleEdit} />}
</div> 
  }
}

