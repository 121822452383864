import React from 'react';
import classNames from 'classnames';
import { state } from '../lib/state';

export default class EditCost extends React.Component {
  state = {
    item: this.props.cost.item,
    cost: this.props.cost.cost,
    category: this.props.cost.category 
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  }

  categories = () => {
    return [...this.props.entities.get('cost').map(c => c.category).valueSeq().toArray(), ...state.get('categories').toArray()];
  }

  save = () => {
    this.props.updateCost([this.props.cost.id, this.props.cost.date, this.state.item, this.state.cost, this.state.category].join(','))
      .then(res => this.props.dismiss());
  }

  render() {
    return <div className={classNames('modal', {
      'is-active': this.props.active
    })}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add Cost</p>
          <button className="delete" aria-label="close" onClick={this.props.dismiss}></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Item</label>
            <div className="control">
              <input className="input" name="item" type="text" value={this.state.item} onChange={this.onChange} />
            </div>
          </div>
          <div className="field">
            <label className="label">Cost</label>
            <div className="control">
              <input className="input" name="cost" type="text" value={this.state.cost} onChange={this.onChange} />
            </div>
          </div>
          <div className="field">
            <label className="label">Category</label>
            <div className="control">
              <div className="select">
                <select name="category" onChange={this.onChange} value={this.state.category}>
                  {this.categories().map((c,i) => <option key={i} value={c}>{c}</option>)}
                </select>
              </div>
            </div>
          </div>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={this.save}>Edit Cost</button>
          <button className="button" onClick={this.props.dismiss}>Cancel</button>
        </footer>
      </div>
    </div>
  }
}
