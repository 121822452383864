import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Other from './Other';
import Free from './Free';
import Cost from './Cost';

const CAT = 'category';
const DAY = 'date';
const FRE = 'free';
const OTH = 'other';

function sorted(a, b, column) {
  if (column === 'cost') {
    if (parseFloat(a[column]) < parseFloat(b[column])) { return -1; }
    if (parseFloat(a[column]) > parseFloat(b[column])) { return 1; }
    if (parseFloat(a[column]) === parseFloat(b[column])) { return 0; }
  }

  if (a[column] < b[column]) { return -1; }
  if (a[column] > b[column]) { return 1; }
  if (a[column] === b[column]) { return 0; }
}

class Main extends React.Component {
  state = {
    categories: [],
    costs: [],
    days: [],
    view: CAT
  }

  componentDidMount() {
    this.setState({ categories: this.load(CAT), days: this.load(DAY) });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.entities.get('cost').count() !== this.props.entities.get('cost').count()) {
      this.setState({ categories: this.load(CAT), days: this.load(DAY) });
    }
  }

  load = type => {
    return this.props.entities
      .get('cost')
      .map(c => c[type]) 
      .toSet()
      .sort()
      .toJS();
  }

  showCosts = (type, value) => {
    if (this.state.costs.length >= 1 && this.state.costs[0][type] === value) {
      return this.setState({ costs: [] });
    }

    return this.setState({ costs: this.props.entities
      .get('cost')
      .filter(c => c[type] === value)
      .valueSeq()
      .toArray() });
  }

  total = (type, value) => {
    return this.props.entities.get('cost')
      .filter(c => c[type] === value)
      .map(c => parseFloat(c.cost))
      .valueSeq()
      .toArray()
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  }

  totalCosts = () => {
    return this.props.entities.get('cost')
      .filter(c => c.cost)
      .map(c => parseFloat(c.cost))
      .valueSeq()
      .toArray()
      .reduce((a, b) => a + b, 0)
      .toFixed(2);
  }

  updateView = view => {
    this.setState({ view: view });
  }

  render() {
    return <div className="has-text-centered">
			<div className="tabs">
				<ul>
          <li onClick={() => this.updateView(CAT)} className={classNames({
            'is-active': this.state.view === CAT
          })}><a>Categories</a></li>
          <li onClick={() => this.updateView(DAY)} className={classNames({
            'is-active': this.state.view === DAY
          })}><a>Days</a></li>
          <li onClick={() => this.updateView(FRE)} className={classNames({
            'is-active': this.state.view === FRE
          })}><a>Free</a></li>
          <li onClick={() => this.updateView(OTH)} className={classNames({
            'is-active': this.state.view === OTH
          })}><a>Other</a></li>
				</ul>
			</div>

      {this.state.view === OTH && <Other {...this.props} />}

      {this.state.view === FRE && <Free {...this.props} />}

      {this.state.view === DAY && <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Day</th>
            <th>Cost</th>
          </tr>
        </thead>
        {this.state.days.map((c, i) => <tbody key={i}>
          <tr key={i}>
            <td><a onClick={() => this.showCosts(DAY, c)}>{c}</a></td>
            <td>{this.total(DAY, c)}</td>
          </tr>
          {this.state.costs.length > 0 && 
            this.state.costs[0].date === c && 
            this.state.costs.map((cc, j) => <tr key={j} style={{ backgroundColor: '#EFEFEF' }}>
              <td colSpan="2"><Cost {...this.props} cost={cc} /></td>
            </tr>)}
        </tbody>)}
        <tbody>
          <tr>
            <td style={{ textAlign: 'right' }}><strong>Total:</strong></td>
            <td>{this.totalCosts()}</td>
          </tr>
        </tbody>
      </table>}

      {this.state.view === CAT && <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Category</th>
            <th>Cost</th>
          </tr>
        </thead>
        {this.state.categories.map((c, i) => <tbody key={i}>
          <tr key={i}>
            <td><a onClick={() => this.showCosts(CAT, c)}>{c}</a></td>
            <td>{this.total(CAT, c)}</td>
          </tr>
          {this.state.costs.length > 0 && 
            this.state.costs[0].category === c && 
            this.state.costs.map((cc, j) => <tr key={j} style={{ backgroundColor: '#EFEFEF' }}>
              <td colSpan="2"><Cost {...this.props} cost={cc} /></td>
            </tr>)}
        </tbody>)}
        <tbody>
          <tr>
            <td style={{ textAlign: 'right' }}><strong>Total:</strong></td>
            <td>{this.totalCosts()}</td>
          </tr>
        </tbody>
      </table>}
    </div>
  }
}

function mapStateToProps(state) {
  return {
    entities: state.entities
  };
}

export const MainContainer = connect(
  mapStateToProps
)(Main);
