// ----------------------
// USERS
// ----------------------
export const LOADING = 'LOADING';
export const LOGIN = 'LOGIN';

// ----------------------
// COSTS
// ----------------------
export const MODIFIED = 'MODIFIED';

// ----------------------
// ENTITIES
// ----------------------
export const CLEAR_ENTITIES = 'CLEAR_ENTITIES';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';
export const RECEIVE_ENTITIES = 'RECEIVE_ENTITIES';
export const RECEIVE_ENTITY = 'RECEIVE_ENTITY';
