import { Record } from 'immutable';

export default class CostRecord extends Record({
    id: null,
    date: null,
    item: '',
    cost: '',
    category: '',
    known: ''
  }) {

  }
