import * as ActionTypes from '../constants/ActionTypes';
import { push } from 'connected-react-router';
import Network from '../lib/network';
import { clearEntity, receiveEntities } from './entities'

const api = Network();

export const loading = bool => ({
  type: ActionTypes.LOADING,
  payload: bool
});

export const redirect = route => {
  return (dispatch, getState) => {
    return dispatch(push(route));
  }
}

export const uploadCosts = formData => {
  return (dispatch, getState) => {
    return api.upload({
      resource: 'upload'
    }, formData)
    .then(res => dispatch(receiveEntities(res)));
  }
}

export const addCost = cost => {
  return (dispatch, getState) => {
    return api.post({
      resource: 'cost'
    }, { csv: cost })
    .then(res => {
      dispatch({
        type: ActionTypes.MODIFIED,
        payload: res.modified
      });
      dispatch(receiveEntities(res));
    });
  }
}

export const updateCost = cost => {
  return (dispatch, getState) => {
    return api.edit({
      resource: 'cost'
    }, { csv: cost })
    .then(res => {
      dispatch({
        type: ActionTypes.MODIFIED,
        payload: res.modified
      });
      dispatch(receiveEntities(res));
    });
  }
}

export const deleteCost = cost => {
  return (dispatch, getState) => {
    return api.delete({
      resource: 'cost'
    }, cost)
    .then(res => {
      dispatch({
        type: ActionTypes.MODIFIED,
        payload: res.modified
      });
      dispatch(clearEntity(cost, 'cost'));
    });
  }
}

export const getCosts = () => {
  return (dispatch, getState) => {
    return api.get({
      resource: 'costs'
    })
    .then(res => {
      dispatch({
        type: ActionTypes.MODIFIED,
        payload: res.modified
      });
      dispatch(receiveEntities(res));
    });
  }
}
