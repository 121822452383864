import { Record } from 'immutable';

export default class UserRecord extends Record({
    first_name: '' ,
    last_name: '',
    email: '',
    role: '',
    is_logged_in: false,
  }) {

  }
